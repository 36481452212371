import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class AuthorGuidelines extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
           
        };

    }

    render() {
        return (
            <div>
                   {/* <!-- Start: Header Section --> */} 
                   <header id="header" className="navbar-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <nav className="navbar navbar-default">
                                            <div className="menu-wrap">
                                                <div className="navbar-header">
                                                    <div className="navbar-brand">
                                                        <h1>
                                                            <Link to={`/`}><img src="images/uvs_e_library_logo.png" alt="UVS e-Library" /></Link>
                                                        </h1>
                                                    </div>
                                                </div>
                                                {/* <!-- Navigation --> */}
                                                <div className="navbar-collapse hidden-sm hidden-xs">
                                                    <ul className="nav navbar-nav">
                                                        <li>
                                                            <Link to={`/`} style={{color:'white'}}>Home</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/callofpapers`} style={{color:'white'}}>Call of Papers</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="mobile-menu hidden-md hidden-lg">
                                            <a href="#mobile-menu"><i className="fa fa-navicon"></i></a>
                                                <div id="mobile-menu">
                                                    <ul>
                                                        <li className="mobile-title">
                                                            <h4>Navigation</h4>
                                                            <a href="#" className="close"></a>
                                                        </li>
                                                        <li>
                                                            <Link to={`/`}>Home</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/callofpapers`}>Call of Papers</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/authorguidelines`}>Author Guidelines</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </header>
                            {/* <!-- End: Header Section -->

                        {/* <!-- Start: Page Banner --> */}
                            <section className="page-banner services-banner">
                                <div className="container">
                                    <div className="banner-header">
                                        <h2 style={{ color: "white" }}>Author Guidelines</h2>
                                        <span className="underline center"></span>
                                        <p className="lead">You are invited to participate.</p>
                                    </div>
                                    <div className="breadcrumb">
                                        <ul>
                                            <li><Link to={`/`}>Home</Link></li>
                                            <li>Author Guidelines</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            {/* <!-- End: Page Banner --> */}
                            <div>&nbsp;</div>
                            
                            <div id="content" className="site-content">
                                <div id="primary" className="content-area">
                                    <main id="main" className="site-main">
                                        <div className="blog-detail-main">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="blog-page">
                                                        <div className="blog-section">
                                                            <article>
                                                                <div className="blog-detail">
                                                                    
                                                                    <div className="post-detail">
                                                                        <div className="entry-content">
                                                                            <h1>Aims & Scopes</h1>
                                                                            <p style={{ paddingTop: 20 }}>
                                                                            <b>Myanmar Journal of Veterinary and Animal Sciences</b> (MJVAS) is a peer-reviewed international journal dedicated to the latest advancements in Veterinary science and Animal science. The goal of this journal is to provide a platform for scientists and academicians all over the world to promote, share, and discuss various new issues and developments in different areas of Veterinary and Animal sciences. 
                                                                            Areas of veterinary science and animal science that will be considered for publication include: all veterinary and animal science related fields, aquaculture, fisheries science, zoo and wildlife medicine, and wildlife conservation. 
                                                                            </p>
                                                                            <div>&nbsp;</div>
                                                                            <h1>Author guidelines</h1>
                                                                            <div>&nbsp;</div>
                                                                            <h2>Text Formatting</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Manuscripts should be submitted in Word.
                                                                            </p>
                                                                            <ul>
                                                                                <li>Use a normal, 12-point Times New Roman for text, with line number and text should be formatted with double line spacing.</li>
                                                                                <li>Use italics for emphasis.</li>
                                                                                <li>Use the automatic page numbering function to number the pages.</li>
                                                                                <li>Do not use field functions.</li>
                                                                                <li>Use tab stops or other commands for indents, not the space bar.</li>
                                                                                <li>Use the table function, not spreadsheets, to make tables.</li>
                                                                            </ul>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                To avoid unnecessary errors you are strongly advised to use the "speel-check" and "grammer-check" functions.
                                                                            </p>
                                                                            <div>&nbsp;</div>
                                                                            <h1>Ethics of Publication</h1>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                            All manuscripts are being checked by the panels of editors and then peer-reviewed to determine the quality of the contents. Copying without stating sources and duplication are considered to be unethical and on such instances all such submitted manuscripts are straight away rejected. Manuscripts must be submitted on the understanding that they have not been published elsewhere and are only being considered by this journal. The submitting author is responsible for ensuring that the article’s publication has been approved by all the other coauthors.
                                                                            </p>
                                                                            <div>&nbsp;</div>
                                                                            <h1>Submission of an article</h1>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                            You may send the attachments of your manuscripts through e-mail at our editorial office at; <b>uvsjournal@gmail.com</b> or submit online from our https://mjvas.com website through "Call of Papers" section.
                                                                            </p>
                                                                            <div>&nbsps;</div>
                                                                            <h1>Referees</h1>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Please submit the names and institutional e-mail addresses of 2 potential referees.
                                                                            </p>
                                                                            <div>&nbsp;</div>
                                                                            <h1>Article Types</h1>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                The journal will consider the following article types.
                                                                            </p>
                                                                            <h2>Research Articles</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Research articles should present the results of an original research study. These manuscripts should describe how the research project was conducted and provide a thorough analysis of the results of the project. Systematic reviews may be submitted as research articles.
                                                                            </p>
                                                                            <h2>Short Communication</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Similar to research articles, but shorter than 4 printed pages, and with a shorter abstract.
                                                                            </p>
                                                                            <h2>Clinical Report</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                A clinical study presents the methodology and results of a study that was performed within a clinical setting. These studies include both clinical trials and retrospective analyses of a body of existing cases. In all cases, clinical studies should include a description of the examined animal group that was involved, along with a thorough explanation of the methodology used in the study and the results that were obtained.
                                                                            </p> 
                                                                            <div>&nbsp;</div>
                                                                            <h1>Review Articles</h1>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                A review article provides an overview of the published literature in a particular subject area.
                                                                            </p>   
                                                                            <h2>Formatting</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                The following information should be included:
                                                                            </p>
                                                                            <div>
                                                                                <ul>
                                                                                    <li>
                                                                                        Manuscript title
                                                                                    </li>
                                                                                    <li>
                                                                                        Full author names
                                                                                    </li>
                                                                                    <li>
                                                                                        Full institutional mailing addresses
                                                                                    </li>
                                                                                    <li>
                                                                                        Email addresses
                                                                                    </li>
                                                                                </ul>        
                                                                            </div>                                             
                                                                            <h1>Abstract</h1>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                The manuscript should contain an abstract. The abstract should be self-contained, citation-free, and should not exceed 300 words.
                                                                            </p>
                                                                            <h1>Introduction</h1>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                This section should be succinct, with no subheadings.
                                                                            </p>
                                                                            <h2>Materials and Methods</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                This part should contain sufficient detail that would enable all procedures to be repeated. It can be divided into subsections if several methods are described.
                                                                            </p>
                                                                            <h2>Results and Discussion</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                This section may be divided into subsections or may be combined.
                                                                            </p>
                                                                            <h2>Main Text (Review Only)</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                This section may be divided into subsections or may be combined.
                                                                            </p>
                                                                            <h2>Conclusions</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                This should clearly explain the main conclusions of the article, highlighting its importance and relevance.
                                                                            </p>
                                                                            <h2>Conflicts of Interest</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Authors must declare all relevant interests that could be perceived as conflicting. Authors should explain why each interest may represent a conflict. If no conflicts exist, the authors should state this. Submitting authors are responsible for coauthors declaring their interests.
                                                                            </p>
                                                                            <h2>Funding Statement</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Authors must state how the research and publication of their article was funded, by naming financially supporting body(s) (written out in full) followed by associated grant number(s) in square brackets (if applicable). If the research did not receive specific funding, but was performed as part of the employment of the authors, please name this employer. If the funder was involved in the manuscript writing, editing, approval, or decision to publish, please declare this.
                                                                            </p>
                                                                            <h2>Acknowledgments</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                All acknowledgments (if any) should be included at the very end of the manuscript before the references. Anyone who made a contribution to the research or manuscript, but who is not a listed author, should be acknowledged (with their permission).
                                                                            </p>
                                                                            <h2>References</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                All references should be cited within the text and uncited references will be removed.<br/>
                                                                                Cite references in the text by name and year in parentheses. Some examples:
                                                                            </p>
                                                                            <div>
                                                                                <ul>
                                                                                    <li>
                                                                                        Negotiation research spans many disciplines (Thompson, 1990).
                                                                                    </li>
                                                                                    <li>
                                                                                        This result was later contradicted by Becker and Seligman (1996).
                                                                                    </li>
                                                                                    <li>
                                                                                        This effect has been widely studied (Abbott, 1991; Barakat et al., 1995a, b; Kelso and Smith, 1998; Medvec et al., 1999, 2000).
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <h1>Example reference style:</h1>
                                                                            <div>&nbsp;</div>
                                                                            <h2>Article within a journal</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Smith JJ. The world of science. Am J Sci. 1999;36:234-5.
                                                                            </p>
                                                                            <h2>Article within a journal (no page numbers)</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Rohrmann S, Overvad K, Bueno-de-Mesquita HB, Jakobsen MU, Egeberg R, Tjønneland A, et al. Meat consumption and mortality - results from the European Prospective Investigation into Cancer and Nutrition. BMC Med. 2013;11:63.
                                                                            </p>
                                                                            <h2>Article within a journal by DOI</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Slifka MK, Whitton JL. Clinical implications of dysregulated cytokine production. Dig J Mol Med. 2000; doi:10.1007/s801090000086.
                                                                            </p>
                                                                            <h2>Article within a journl supplement</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Frumin AM, Nussbaum J, Esposito M. Functional asplenia: demonstration of splenic activity by bone marrow scan. Blood 1979;59 Suppl 1:26-32.
                                                                            </p>
                                                                            <h2>Book chapter, or an article within a book</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Wyllie AH, Kerr JFR, Currie AR. Cell death: the significance of apoptosis. In: Bourne GH, Danielli JF, Jeon KW, editors. International review of cytology. London: Academic; 1980. p. 251-306.
                                                                                <br/><br/>OnlineFirst chapter in a series (without a volume designation but with a DOI)
                                                                                <br/><br/>Saito Y, Hyuga H. Rate equation approaches to amplification of enantiomeric excess and chiral symmetry breaking. Top Curr Chem. 2007. doi:10.1007/128_2006_108.
                                                                            </p>
                                                                            <h2>Complete book, authored</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Blenkinsopp A, Paxton P. Symptoms in the pharmacy: a guide to the management of common illness. 3rd ed. Oxford: Blackwell Science; 1998.
                                                                                <br/><br/>
                                                                                In the case of publications in any language other than English, the original title should be transliterated, and a notation such as "(in Myanmar)" or "(in Greek, with English abstract)" should be added.
                                                                                <br/><br/>
                                                                                Work accepted for publication but not yet published should be referred to as "in press".
                                                                            </p>
                                                                            <h2>Thesis</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Wai Mon Htet. Study on prevalence of Toxoplasma gondii infection household cats within Yezin Area. MVSc Dissertation, University of Veterinary Science, Yezin, Myanmar; 2012.
                                                                            </p>
                                                                            <h2>Conference</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Yadanar Khaing, Lat Lat Htun, Myint Myint Hmoon Hla Myet Chel, Yu Nandi Thaw Nyein Chan Soe, Shwe Yee Win, Su Su Thein, Saw Bawm. Microscopic Occurrence of tick-borne pathogens of dogs in Hlaing Township, Yangon City and Pyin Oo Lwin Township. Myanmar Univesities' Research Conference; 2020.
                                                                            </p>
                                                                            <h2>Online Document</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Doe J. Title of subordinate document. In: The dictionary of substances and their effects. Royal Society of Chemistry. 1999. http://www.rsc.org/dose/title of subordinate document. Accessed on 15 Jan 1999.
                                                                            </p>
                                                                            <h2>Online Database</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Healthwise Knowledgebase. US Pharmacopeia, Rockville. 1998. http://www.healthwise.org. Accessed on 21 Sept 1998.
                                                                            </p>
                                                                            <h2>Supplementary material/private homepage</h2>
                                                                            <p style={{ paddingTop: 10 }}>Doe J. Title of supplementary material. 2000. http://www.privatehomepage.com. Accessed on 22 Feb 2000.</p>
                                                                            <h2>Univesity site</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Doe J. Title of preprint. http://www.uni-heidelberg.de/mydata.html (1999). Accessed on 25 Dec 1999.
                                                                            </p>
                                                                            <h2>FTP site</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Doe J. Trivial HTTP, RFC2169. ftp://ftp.isi.edu/in-notes/rfc2169.txt (1999). Accessed on 12 Nov 1999.
                                                                            </p>
                                                                            <h2>Organization site</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                ISSN International Centre: The ISSN register. http://www.issn.org (2006). Accessed on 20 Feb 2007.
                                                                            </p>
                                                                            <h2>Dataset with persistent identifier</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Zheng L-Y, Guo X-S, He B, Sun L-J, Peng Y, Dong S-S, et al. Genome data from sweet and grain sorghum (Sorghum bicolor). GigaScience Database. 2011. http://dx.doi.org/10.5524/100012.
                                                                            </p>
                                                                            <h2>Preparation of Figures</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Upon submission of an article, authors should include all figures and tables in the PDF file of the manuscript. Figures and tables should not be submitted in separate files. If the article is accepted, authors will be asked to provide the source files of the figures. Each figure should be supplied in a separate electronic file. All figures should be cited in the manuscript in a consecutive order.
                                                                            </p>
                                                                            <h2>Preparation of Tables</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Tables should be cited consecutively in the text. Every table must have a descriptive title and if numerical measurements are given, the units should be included in the column heading. Vertical rules should not be used.
                                                                            </p>
                                                                            <h2>Article Processing Charges (APC)</h2>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                APC will be free of charges if submitted during 2020, after that APC will be 30,000 MMK (for Myanmar) and 30 USD (for foreigners).
                                                                            </p>
                                                                        </div>
                                                                       <div>&nbsp;</div>
                                                                       <div>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                            <div>&nbsp;</div>
                    {/* <!-- Start: Footer --> */}
                    <footer className="site-footer">
                        <div className="container">
                            <div id="footer-widgets">
                                <div className="row">
                                    <div className="col-sm widget-container text-center">
                                        <div id="text-2" className="widget widget_text">
                                            <h3 className="footer-widget-title">About UVS e-Library</h3>
                                            <span className="underline left"></span>
                                            <div className="textwidget">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking.
                                            </div>
                                            <address>
                                                <div className="info">
                                                    <i className="fa fa-location-arrow"></i>
                                                    <span>University of Veterinary Science, Yezin, Zayar Thiri Township, Naypyidaw, Myanmar.</span>
                                                </div>
                                                <div className="info">
                                                    <i className="fa fa-envelope"></i>
                                                    <span><a href="mailto:contact@uvsyezin.edu.mm">contact@uvsyezin.edu.mm</a></span>
                                                </div>
                                            </address>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>                
                        </div>
                    </footer>
                    {/* <!-- End: Footer --> */}

            </div>
        );
    }
}
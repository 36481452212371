import React, { Component } from "react";
import { api } from "../../config/config";
import { Link } from "react-router-dom";
var md5 = require("md5");
var CryptoJS = require("crypto-js");

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
            user_full_name: "",
            user_email: "",
            user_password: "",
            confirm_password: "",
            user_login_email: "",
            user_login_password: "",            
        };
        this.VerifyLogin = this.VerifyLogin.bind(this);
        this.Register = this.Register.bind(this);
        this.handleUserLoginEmailChange = this.handleUserLoginEmailChange.bind(this);
        this.handleUserLoginPasswordChange = this.handleUserLoginPasswordChange.bind(this);
        this.handleUserFullNameChange = this.handleUserFullNameChange.bind(this);
        this.handleUserEmailChange = this.handleUserEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    }

    handleUserLoginEmailChange(e) {
        this.setState({ user_login_email: e.target.value });
    }

    handleUserLoginPasswordChange(e) {
        this.setState({ user_login_password: e.target.value });
    }

    handleUserFullNameChange(e) {
        this.setState({ user_full_name: e.target.value });
    }

    handleUserEmailChange(e) {
        this.setState({ user_email: e.target.value });
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    handleConfirmPasswordChange(e) {
        this.setState({ confirm_password: e.target.value });
    }

    Register(e) {
        if(this.state.user_password != "" && this.state.confirm_password != ""){
            if( this.state.user_password != this.state.confirm_password){
                alert( "Password and Confirm Password must be same" );
                e.preventDefault();
                return;
            }
        }

        if (this.state.user_full_name != "" && this.state.user_email != "" && this.state.user_password != "") {

            fetch(api + "user/checkLogin/", {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    user_login_email: this.state.user_login_email,
                    user_login_password: md5(this.state.user_login_password)
                })
            }).then(res => res.json())
                .then(data => {
                    if (data.status) {
                        localStorage.setItem('user_info', JSON.stringify(data.data[0]));
                        this.props.history.push("/dashboard");
                        window.location.replace("/admin/dashboard");
                        // window.location.replace("/dashboard");
                    } else {
                        this.setState({
                            error_msg: data.message
                        });
                    }
                })
                .catch(e => console.log(e.text));
        }else{
            if(this.state.user_full_name  == ""){
                alert("Please enter Full Name");
            }
            else if(this.state.user_email == ""){
                alert("Please enter your email to register!");
            }else if(this.state.user_password == ""){
                alert("Please enter password to register!");
            }else if(this.state.confirm_password == ""){
                alert("Please enter confirm password to register!");
            }
        }

        e.preventDefault();
    }

    VerifyLogin(e) {
        if (this.state.user_login_email != "" && this.state.user_login_password != "") {
            fetch(api + "user/checkLogin/", {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    user_login_email: this.state.user_login_email,
                    user_login_password: md5(this.state.user_login_password)
                })
            }).then(res => res.json())
                .then(data => {
                    if (data.status) {
                        localStorage.setItem('user_info', JSON.stringify(data.data[0]));
                        this.props.history.push("/dashboard");
                        window.location.replace("/admin/dashboard");
                        // window.location.replace("/dashboard");
                    } else {
                        this.setState({
                            error_msg: data.message
                        });
                    }
                })
                .catch(e => console.log(e.text));
        }else{
            if(this.state.user_login_email == ""){
                alert("Please enter login email!");
            }else if(this.state.user_login_password == ""){
                alert("Please enter your login password");
            }
        }
        e.preventDefault();
    }

    render() {
        return (
            <div>
                   {/* <!-- Start: Header Section --> */}
                   <header id="header" className="navbar-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <nav className="navbar navbar-default">
                                            <div className="col-sm-12">
                                                {/* <!-- Header Topbar --> */}
                                                <div className="header-topbar hidden-sm hidden-xs">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="topbar-info">
                                                                <a href="tel: +95 67 341 6528"><i className="fa fa-phone"></i>+95 67 341 6528</a>
                                                                <span>/</span>
                                                                <a href="mailto:info@uvs.edu.mm"><i className="fa fa-envelope"></i>info@uvs.edu.mm</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="topbar-links">
                                                                <Link to={`/login`}><i className="fa fa-lock"></i>Login / Register</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-light">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="navbar-header">
                                                                <div className="navbar-brand">
                                                                    <h1>
                                                                        <Link to={`/`}><img src="images/mjvas_logo.png" alt="MJVAS" /></Link>
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="navbar-collapse hidden-sm hidden-xs">
                                                                <ul className="nav navbar-nav">
                                                                    <li className="active">
                                                                        <Link to={`/`}>Home</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to={`/`}>Call of papers</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to={`/`}>Author Guidelines</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mobile-menu hidden-lg hidden-md">
                                            <a href="#mobile-menu"><i className="fa fa-navicon"></i></a>
                                                <div id="mobile-menu">
                                                    <ul>
                                                        <li className="mobile-title">
                                                            <h4>Navigation</h4>
                                                            <a href="#" className="close"></a>
                                                        </li>
                                                        <li>
                                                            <Link to={`/`}>Home</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/`}>Call of papers</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/`}>Author Guidelines</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </header>
                            {/* <!-- End: Header Section --> */}
                        
                        {/* <!-- Start: Page Banner --> */}
                        <section className="page-banner services-banner">
                            <div className="container">
                               
                                <div className="breadcrumb">
                                    <ul>
                                        <li><a href="index-2.html">Home</a></li>
                                        <li>Signin</li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        {/* <!-- End: Page Banner --> */}
                        {/* <!-- Start: Cart Section --> */}
                        <div id="content" className="site-content">
                            <div id="primary" className="content-area">
                                <main id="main" className="site-main">
                                    <div className="signin-main">
                                        <div className="container">
                                            <div className="woocommerce">
                                                <div className="woocommerce-login">
                                                    <div className="company-info signin-register">
                                                        <div className="col-md-5 col-md-offset-1 border-dark-left">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="company-detail bg-dark margin-left">
                                                                        <div className="signin-head">
                                                                            <h2 style={{ color: "white" }}>Sign in</h2>
                                                                            <span className="underline left"></span>
                                                                        </div>
                                                                        <form className="login" method="post">
                                                                            <p className="form-row form-row-first input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Enter your email</span>  
                                                                                    <span className="second-letter">*</span>
                                                                                </label>
                                                                                <input type="email" required className="input-text" onChange={this.handleUserLoginEmailChange} name="userLoginEmail" id="userLoginEmail" value={this.state.user_login_email} />
                                                                            </p>
                                                                            <p className="form-row form-row-last input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Password</span>  
                                                                                    <span className="second-letter">*</span>
                                                                                </label>
                                                                                <input type="password" id="password" name="password" className="input-text" onChange={ this.handleUserLoginPasswordChange } value={ this.state.user_login_password } required />
                                                                            </p>
                                                                            <div className="clear"></div>
                                                                            <div className="password-form-row">
                                                                                {/* <p className="form-row input-checkbox">
                                                                                    <input type="checkbox" value="forever" id="rememberme" name="rememberme" />
                                                                                    <label className="inline" for="rememberme">Remember me</label>
                                                                                </p> */}
                                                                                <p className="lost_password">
                                                                                    <a href="#">Forgot Password?</a>
                                                                                </p>
                                                                            </div>
                                                                            <input type="submit" value="Login" name="login" className="button btn btn-default" onClick={this.VerifyLogin} />
                                                                            <div className="clear"></div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 border-dark new-user">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="company-detail new-account bg-light margin-right">
                                                                        <div className="new-user-head">
                                                                            <h2>Register MJVAS Member</h2>
                                                                            <span className="underline left"></span>
                                                                            <p>Join us to be able to publish your journals and become one of MJVAS family members.</p>
                                                                        </div>
                                                                        <form className="login" method="post">
                                                                            <p className="form-row form-row-first input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Enter your full name</span>  
                                                                                    <span className="second-letter">*</span>
                                                                                </label>
                                                                                <input type="text" required className="input-text" onChange={this.handleUserFullNameChange} name="userFullName" id="userFullName" value={this.state.user_full_name} />
                                                                            </p>
                                                                            <p className="form-row form-row-first input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Enter your Email</span>  
                                                                                    <span className="second-letter">*</span>
                                                                                </label>
                                                                                <input type="email" required className="input-text" onChange={this.handleUserEmailChange} name="userEmail" id="userEmail" value={this.state.user_email} />
                                                                            </p>
                                                                            <p className="form-row input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Password</span>  
                                                                                    <span className="second-letter">*</span>
                                                                                </label>
                                                                                <input type="password" id="password" name="password" className="input-text" onChange={this.handlePasswordChange} value={this.state.user_password} required />
                                                                            </p>   
                                                                            <p className="form-row input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Re-type your password to confirm</span>  
                                                                                    <span className="second-letter">*</span>
                                                                                </label>
                                                                                <input type="password" id="confirm_password" name="confirm_password" className="input-text" onChange={this.handleConfirmPasswordChange} value={this.state.confirm_password} required />
                                                                            </p>                                                                             
                                                                            <div className="clear"></div>
                                                                            <input type="submit" value="Register" name="Register" className="button btn btn-default" onClick={this.Register} />
                                                                            <div className="clear"></div>
                                                                        </form> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                        {/* <!-- End: Cart Section --> */}
                        
                       
                        {/* <!-- Start: Footer --> */}
                        <footer className="site-footer">
                                <div className="container">
                                    <div id="footer-widgets">
                                        <div className="row">
                                            <div className="text-center">
                                                <div id="text-2" className="widget widget_text">
                                                    <h4 className="footer-widget-title">About MJVAS (Myanmar Journal of Veterinary and Animal Sciences) </h4>
                                                    <span className="underline left"></span>
                                                    <div className="textwidget text-center">
                                                    We are happy to inform you that Myanmar Journal of Veterinary and Animal Sciences (MJVAS) (ISSN: soon) (published by University of Veterinary Science, Yezin, Nay Pyi Taw) is launching its first issue soon. We would like to invite you to contribute your Research Paper for publication in MJVAS.
                                                    </div>
                                                    <address>
                                                        <div className="info">
                                                            <i className="fa fa-location-arrow"></i>
                                                            <span>University of Veterinary Science, Yezin, Zayar Thiri Township, Naypyidaw, Myanmar.</span>
                                                        </div>
                                                        <div className="info">
                                                            <i className="fa fa-envelope"></i>
                                                            <span><a href="mailto:contact@uvsyezin.edu.mm">contact@uvsyezin.edu.mm</a></span>
                                                        </div>
                                                    </address>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>                
                                </div>
                            </footer>
                            {/* <!-- End: Footer --> */}
            </div>
        );
    }
}
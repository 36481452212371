import React, { Component } from "react";
import { api, base_url } from "../../config/config";
import { Link } from "react-router-dom";
import Select, {optionPorps} from "react-select";
import  Autocomplete  from "../Autocomplete/autocomplete"

// const uuid = {
//     v4() {
//       return Math.random();
//     }
//   };

  const customStyles = {
    control: base => ({
      ...base,
      height: 56,
      backgroundColor: 'transparent',
      color: '#bababa',
      borderColor:'#595959',
      borderWidth: 3,
      borderRadius: 0,
    }),
    option: provided => ({
        ...provided,
        color: 'black'
    }),
    singleValue: provided => ({
        ...provided,
        color: '#bababa'
    })
  };

  const customSearchStyles = {
    control: base => ({
      ...base,
      height: 56,
      borderRadius: 0,
    }),
    option: provided => ({
        ...provided,
        color: 'black'
    }),
    singleValue: provided => ({
        ...provided,
        color: '#bababa'
    })
  };

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
            mode: "home",   // home | list | view
            search_keyword: "",
            book_info: null,
            book_data: [],
            new_books: [],
            book_author_id: "",
            book_category_id: "",
            category_data: [],
            category_obj: {
                label: "- All Category -",
                value: 0
            },
            author_data: [],
            author_obj: {
                label: "- All Author -",
                            value: 0
            }
        };
    }

    componentDidMount() {
        // this.LoadBookNames();
        // this.LoadCategorySelectionData();
        // this.LoadAuthorSelectionData();
        // this.LoadNewReleaseBooksData();
    }

    LoadNewReleaseBooksData = () => {
        try {
            fetch(api + "book/getNewReleasedBooks")
              .then(response => response.json())
              .then(res => {
                    this.setState({ new_books: res.data });
            });
        } catch (ex) {
            this.setState({ error_msg: ex });
        }
    }

    GoHome = e => {
        this.setState({ mode: "home"})
    }

    BookListing = e => {
        e.preventDefault();
        try {
            fetch(api + "book/getBookByFilter", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                category_id: this.state.category_obj.value,
                author_id: this.state.author_obj.value,
                search_keyword: this.state.search_keyword,
              })
            })
            .then(response => response.json())
            .then(res => {
              this.setState({ book_data: res.data, mode: "list" });
            });
        } catch (ex) {
          this.setState({ error_msg: ex });
        }
    }
  
    BookView = e => {
        this.setState({ mode: "view" });
    }

    LoadBookNames = () => {
        try {
            fetch(api + "book/getBookNames")
              .then(response => response.json())
              .then(res => {
                    this.setState({ book_data: res.names });
                    console.log(this.state.book_data)
            });
        } catch (ex) {
            this.setState({ error_msg: ex });
        }
    }

    LoadCategorySelectionData = () => {
        try {
            fetch(api + "category/getCategoryValueLabel")
              .then(response => response.json())
              .then(res => {
                    var pleaseSelect = { "value": "", "label": "- All Category -" };
                    res.data.unshift(pleaseSelect);
                    this.setState({ category_data: res.data });
            });
        } catch (ex) {
            this.setState({ error_msg: ex });
        }
      }

      LoadAuthorSelectionData = () => {
        try {
            fetch(api + "author/getAuthorValueLabel")
              .then(response => response.json())
              .then(res => {
                    var pleaseSelect = { "value": "", "label": "- All Author -" };
                    res.data.unshift(pleaseSelect);
                    this.setState({ author_data: res.data });
            });
        } catch (ex) {
            this.setState({ error_msg: ex });
        }
      }

    handleHomePageSearchKeywordChange = e => {
        this.setState({ search_keyword: e.target.value });
    }

    handleChangeBookAuthorID = e => {
        this.setState({ author_obj: e, book_author_id: e.value })
    };

    handleChangeBookCategoryID = e => {
        this.setState({ category_obj: e, book_category_id: e.value })
    };

    handleViewBook = e => {
        try {
            const jsonString = e.target.getAttribute('data-json');
            const bookObject = JSON.parse(jsonString);
            this.setState({ book_info:bookObject, mode: "view" })
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
    }

    render() {
        return (
            <div>
                {
                    this.state.mode == "home" ? 
                        <div>
                            {/* <!-- Start: Header Section --> */}
                            <header id="header" className="navbar-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <nav className="navbar navbar-default">
                                            <div className="col-sm-12">
                                                {/* <!-- Header Topbar --> */}
                                                <div className="header-topbar hidden-sm hidden-xs">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="topbar-info">
                                                                <a href="tel: +95 67 341 6528"><i className="fa fa-phone"></i>+95 67 341 6528</a>
                                                                <span>/</span>
                                                                <a href="mailto:info@uvs.edu.mm"><i className="fa fa-envelope"></i>info@uvs.edu.mm</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="topbar-links">
                                                                <Link to={`/login`}><i className="fa fa-lock"></i>Login / Register</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-light">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="navbar-header">
                                                                <div className="navbar-brand">
                                                                    <h1>
                                                                        <Link to={`/`}><img src="images/mjvas_logo.png" alt="MJVAS" /></Link>
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="navbar-collapse hidden-sm hidden-xs">
                                                                <ul className="nav navbar-nav">
                                                                    <li className="active">
                                                                        <Link to={`/`}>Home</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to={`/callofpapers`}>Call of papers</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to={`/authorguidelines`}>Author Guidelines</Link>
                                                                    </li>
                                                                    {/* <li>
                                                                        <Link to={`/login`}><i className="fa fa-lock"></i>Login</Link>
                                                                    </li> */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mobile-menu hidden-lg hidden-md">
                                            <a href="#mobile-menu"><i className="fa fa-navicon"></i></a>
                                                <div id="mobile-menu">
                                                    <ul>
                                                        <li className="mobile-title">
                                                            <h4>Navigation</h4>
                                                            <a href="#" className="close"></a>
                                                        </li>
                                                        <li>
                                                            <Link to={`/`}>Home</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/callofpapers`}>Call of papers</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/authorguidelines`}>Author Guidelines</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/login`}><i className="fa fa-lock"></i>Login</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </header>
                            {/* <!-- End: Header Section --> */}
                            
                            {/* <!-- Start: Slider Section --> */}
                            <div data-ride="carousel" className="carousel slide" id="home-v1-header-carousel">
                                {/* <!-- Carousel slides --> */}
                                <div className="carousel-inner">
                                    <div className="item active">
                                        <figure>
                                            <img alt="Home Slide" src="images/header-slider/home-v2/header-slide.jpg" />
                                        </figure>
                                        <div className="container">
                                            <div className="carousel-caption">
                                                <h3>Explore with us!</h3>
                                                <h2>Discover what is new</h2>
                                                <p>MJVAS makes learning more easy by utilizing the technology. Varieties of journals are available on MJVAS portal and you can browse through easily with different filtering carieteria such as keywords, authors and categories.</p>
                                                {/* <div className="slide-buttons hidden-sm hidden-xs">    
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                    <a href="#" className="btn btn-default">Purchase</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <figure>
                                            <img alt="Home Slide" src="images/header-slider/home-v2/header-slide.jpg" />
                                        </figure>
                                        <div className="container">
                                            <div className="carousel-caption">
                                                <h3>Share your knowledge with learners!</h3>
                                                <h2>Publish with us</h2>
                                                <p>Share what you researched and found with the active learners like you. MJVAS provide the most simple and easy way to share your knowledge in your industry.</p>
                                                {/* <div className="slide-buttons hidden-sm hidden-xs">    
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                    <a href="#" className="btn btn-default">Purchase</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="item">
                                        <figure>
                                            <img alt="Home Slide" src="images/header-slider/home-v2/header-slide.jpg" />
                                        </figure>
                                        <div className="container">
                                            <div className="carousel-caption">
                                                <h3>Online Learning Anytime, Anywhere!</h3>
                                                <h2>Discover Your Roots</h2>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words.</p>
                                                <div className="slide-buttons hidden-sm hidden-xs">    
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                    <a href="#" className="btn btn-default">Purchase</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <!-- Indicators --> */}
                                <ol className="carousel-indicators">
                                    <li data-target="#home-v1-header-carousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#home-v1-header-carousel" data-slide-to="1"></li>
                                    {/* <li data-target="#home-v1-header-carousel" data-slide-to="2"></li> */}
                                </ol>
                            </div>
                            {/* <!-- End: Slider Section --> */}

                            {/* <!-- Start: Search Section --> */}
                            <section className="search-filters">
                                <div className="container">
                                    <div className="filter-box">
                                        <h3>What are you looking for at MJVAS?</h3>
                                        <form>
                                            <div className="col-md-10 col-sm-6">
                                                <div className="form-group">
                                                    <label className="sr-only" for="keywords">Search by Keyword</label>
                                                    <input className="form-control" placeholder="Search by Keyword" id="keywords" name="keywords" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control" type="submit" value="Search" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </section>
                            {/* <!-- End: Search Section --> */}

                            {/* <!-- Start: Features --> */}
                            <section className="features">
                                <div className="container">
                                    <ul>
                                        <li className="yellow-hover">
                                            <div className="feature-box">
                                                <i className="yellow"></i>
                                                <h3>Publish With Us</h3>
                                                <p>Join us to publish your journal, article or books on our MJVAS portal</p>
                                                <a className="yellow" href="#">
                                                    Get Author Query Form <i className="fa fa-long-arrow-right"></i>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="light-green-hover">
                                            <div className="feature-box">
                                                <i className="light-green"></i>
                                                <h3>Author Guidelines</h3>
                                                <p>This section will help you write the best article possible by providing you with points to consider.</p>
                                                <a className="light-green" href="#">
                                                    View Author Guidelines <i className="fa fa-long-arrow-right"></i>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="blue-hover">
                                            <div className="feature-box">
                                                <i className="blue"></i>
                                                <h3>Call of papers</h3>
                                                <p>MJVAS will publish Original papers, Review Papers, Short Communication and Clinical Reports.</p>
                                                <a className="blue" href="#">
                                                    View More <i className="fa fa-long-arrow-right"></i>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                            {/* <!-- End: Features --> */}

                            {/* <!-- Start: Welcome Section --> */}
                            <section className="welcome-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="welcome-wrap text-center">
                                            <h2 className="section-title">Welcome to Myanmar Journal of Veterinary and Animal Sciences</h2>
                                            <span className="underline left"></span>
                                            <p>MJVAS is for active learners (students, staffs, tutors and professors) who wants to access latest vet and animal science articles on their finger tips. It makes learning more easy by utilizing the technology. Varieties of journals are available on MJVAS portal and you can browse through easily with different filtering carieteria such as keywords, authors and categories.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="welcome-image"></div>
                            </section>
                            {/* <!-- End: Welcome Section --> */}
                            
                            {/* <!-- Start: Footer --> */}
                            <footer className="site-footer">
                                <div className="container">
                                    <div id="footer-widgets">
                                        <div className="row">
                                            <div className="text-center">
                                                <div id="text-2" className="widget widget_text">
                                                    <h4 className="footer-widget-title">About MJVAS (Myanmar Journal of Veterinary and Animal Sciences) </h4>
                                                    <span className="underline left"></span>
                                                    <div className="textwidget text-center">
                                                    We are happy to inform you that Myanmar Journal of Veterinary and Animal Sciences (MJVAS) (ISSN: soon) (published by University of Veterinary Science, Yezin, Nay Pyi Taw) is launching its first issue soon. We would like to invite you to contribute your Research Paper for publication in MJVAS.
                                                    </div>
                                                    <address>
                                                        <div className="info">
                                                            <i className="fa fa-location-arrow"></i>
                                                            <span>University of Veterinary Science, Yezin, Zayar Thiri Township, Naypyidaw, Myanmar.</span>
                                                        </div>
                                                        <div className="info">
                                                            <i className="fa fa-envelope"></i>
                                                            <span><a href="mailto:contact@uvsyezin.edu.mm">contact@uvsyezin.edu.mm</a></span>
                                                        </div>
                                                    </address>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>                
                                </div>
                            </footer>
                            {/* <!-- End: Footer --> */}
                        </div>
                    :
                    this.state.mode == "list" ? 
                        <div>
                                {/* <!-- Start: Header Section --> */}
                                <header id="header" className="navbar-wrapper">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <nav className="navbar navbar-default">
                                                <div className="menu-wrap">
                                                    <div className="navbar-header">
                                                        <div className="navbar-brand">
                                                            <h1>
                                                                <Link to={`/`}><img src="images/uvs_e_library_logo.png" alt="UVS e-Library" /></Link>
                                                            </h1>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Navigation --> */}
                                                    <div className="navbar-collapse hidden-sm hidden-xs">
                                                        <ul className="nav navbar-nav">
                                                            <li>
                                                                <a href={base_url}>Home</a>
                                                            </li>
                                                            <li>
                                                                <a href="https://mjvas.com.mm">UVS Journals</a>
                                                            </li>
                                                            {/* <li className="dropdown">
                                                                <a data-toggle="dropdown" className="dropdown-toggle disabled" href="news-events-list-view.html">News &amp; Events</a>
                                                                <ul className="dropdown-menu">
                                                                    <li><a href="news-events-list-view.html">News &amp; Events List View</a></li>
                                                                    <li><a href="news-events-detail.html">News &amp; Events Detail</a></li>
                                                                </ul>
                                                            </li>
                                                            <li><a href="services.html">Services</a></li>
                                                            <li><a href="contact.html">Contact</a></li> */}
                                                        </ul>
                                                        {/* <!-- Header Topbar --> */}
                                                        <div className="header-topbar hidden-md">
                                                            <div className="topbar-links">
                                                                <a href={ base_url + "admin"}><i className="fa fa-lock"></i>Login</a>
                                                            </div>
                                                        </div>
                                                        {/* <!-- Header Topbar --> */}
                                                    </div>
                                                </div>
                                                <div className="mobile-menu hidden-md hidden-lg">
                                                <a href="#mobile-menu"><i className="fa fa-navicon"></i></a>
                                                    <div id="mobile-menu">
                                                        <ul>
                                                            <li className="mobile-title">
                                                                <h4>Navigation</h4>
                                                                <a href="#" className="close"></a>
                                                            </li>
                                                            <li>
                                                                <a href={base_url}>Home</a>
                                                            </li>
                                                            <li>
                                                                <a href="https://mjvas.com.mm">UVS Journals</a>
                                                            </li>
                                                        
                                                        </ul>
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </header>
                                    
                                    {/* <!-- End: Header Section --> */}

                                    {/* <!-- Start: Page Banner --> */}
                                    <section className="page-banner services-banner">
                                        <div className="container">
                                        <div className="breadcrumb">
                                            <ul>
                                                <li><a href="index-2.html">Home</a></li>
                                                <li>Books & Media</li>
                                            </ul>
                                        </div>
                                        </div>
                                    </section>
                                    {/* <!-- End: Page Banner --> */}

                                    {/* <!-- Start: Products Section --> */}
                                    <div id="content" className="site-content">
                                        <div id="primary" className="content-area">
                                            <main id="main" className="site-main">
                                                <div className="books-media-gird">
                                                    <div className="container">
                                                        <div className="row">
                                                            {/* <!-- Start: Search Section --> */}
                                                            <section className="search-filters">
                                                                <div className="container">
                                                                    <div className="filter-box">
                                                                        <h3>What are you looking for at the library?</h3>
                                                                        <form>
                                                                            <div className="col-md-4 col-sm-6">
                                                                                <div className="form-group">
                                                                                    <label className="sr-only" for="keywords">Search by Keyword</label>
                                                                                    <input className="form-control" placeholder="Search by Keyword" onChange={this.handleHomePageSearchKeywordChange} type="text"></input>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 col-sm-6">
                                                                          
                                                                                <div className="form-group">
                                                                                    <Select
                                                                                        styles={customSearchStyles}
                                                                                        placeholder="Search By Author"
                                                                                        required
                                                                                        id="book_author_id"
                                                                                        value={this.state.author_obj}
                                                                                        options={this.state.author_data}
                                                                                        onChange={this.handleChangeBookAuthorID }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 col-sm-6">
                                                                                <div className="form-group">
                                                                                    <Select
                                                                                        styles={customSearchStyles}
                                                                                        placeholder="Search by Category"
                                                                                        required
                                                                                        id="book_category_id"
                                                                                        value={this.state.category_obj}
                                                                                        options={this.state.category_data}
                                                                                        onChange={this.handleChangeBookCategoryID }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2 col-sm-6">
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="submit" value="Search" onClick={this.BookListing}></input>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            {/* <!-- End: Search Section --> */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-9 col-md-push-3">
                                                                <div className="filter-options margin-list">
                                                                    <div className="row">   
                                                                        <div className="col-md-4 col-sm-4 pull-right ">
                                                                            <div className="filter-result text-right">Showing total: {<label>{this.state.book_data.length} book(s)</label>}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="books-gird">
                                                                    <ul>
                                                                        {
                                                                            this.state.book_data.length > 0 ?
                                                                                this.state.book_data.map((book) => {
                                                                                    return <li>
                                                                                        <figure>
                                                                                            <img src={ api + "uploads/cover/" + book.book_cover }  alt="Book"></img>
                                                                                            <figcaption>
                                                                                                <p><strong>{book.book_name}</strong></p>
                                                                                                <p><strong>Author:</strong>  {book.author_name}</p>
                                                                                            </figcaption>
                                                                                        </figure> 
                                                                                        {/* <div className="book-list-icon blue-icon"></div> */}
                                                                                        <div className="single-book-box">
                                                                                            <div className="post-detail">
                                                                                                <header className="entry-header">
                                                                                                    <h3 className="entry-title"><a href="books-media-detail-v1.html">{book.book_name}</a></h3>
                                                                                                    <ul>
                                                                                                        <li><strong>Author:</strong> {book.author_name}</li>
                                                                                                        <li><strong>ISBN:</strong> {book.book_isbn}</li>
                                                                                                    </ul>
                                                                                                </header>
                                                                                                <div className="entry-content">
                                                                                                    <p>{book.book_intro}</p>
                                                                                                </div>
                                                                                                <footer className="entry-footer">
                                                                                                    {/* <button type='button' id='btn_remove' className='btn btn-danger'><i className='remove-style fa fa-times' data-toggle='modal' data-target='#removeModal'> </i></button> */}
                                                                                                    <button className="btn btn-primary" onClick={this.handleViewBook} data-json={JSON.stringify(book)}>View</button>
                                                                                                </footer>
                                                                                            </div>
                                                                                        </div>                                       
                                                                                    </li>
                                                                                })
                                                                                
                                                                            :

                                                                            <div className="center-content">
                                                                                <h2 className="section-title">No books found for your search!</h2>
                                                                                <span className="underline center"></span>
                                                                                <p className="lead">Please change search crieteria and make new search.</p>
                                                                                <div className="clearfix"></div>
                                                                            </div>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                        
                                                            </div>
                                                            <div className="col-md-3 col-md-pull-9">
                                                                <aside id="secondary" className="sidebar widget-area" data-accordion-group>
                                                                    <div className="widget widget_recent_entries">
                                                                        <h4 className="widget-title">New Release Items</h4>
                                                                        <ul>
                                                                            {
                                                                                this.state.new_books.length > 0 ? 
                                                                                this.state.new_books.map((book) => {
                                                                                    return <li>
                                                                                                <figure>
                                                                                                    <img src={api + "uploads/cover/" + book.book_cover } width="100" height="60" alt="product" />
                                                                                                </figure>
                                                                                                <a href="#">{ book.book_name }</a>
                                                                                                <span className="price"><strong>Author:</strong> book.author_name</span>
                                                                                                <span><strong>ISBN:</strong> {book.book_isbn}</span>
                                                                                                <div className="clearfix"></div>
                                                                                            </li>
                                                                                })
                                                                            :
                                                                                <li>
                                                                                    <span className="price">No new books!</span>
                                                                                </li>
                                                                            }
                                                                        </ul>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                </aside>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>
                                        </div>
                                    </div>
                                    {/* <!-- End: Products Section --> */}
                                    <div>&nbsp;</div>
                                    {/* <!-- Start: Footer --> */}
                            <footer className="site-footer">
                                <div className="container">
                                    <div id="footer-widgets">
                                        <div className="row">
                                            <div className="col-sm widget-container text-center">
                                                <div id="text-2" className="widget widget_text">
                                                    <h3 className="footer-widget-title">About UVS e-Library</h3>
                                                    <span className="underline left"></span>
                                                    <div className="textwidget">
                                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking.
                                                    </div>
                                                    <address>
                                                        <div className="info">
                                                            <i className="fa fa-location-arrow"></i>
                                                            <span>University of Veterinary Science, Yezin, Zayar Thiri Township, Naypyidaw, Myanmar.</span>
                                                        </div>
                                                        <div className="info">
                                                            <i className="fa fa-envelope"></i>
                                                            <span><a href="mailto:contact@uvsyezin.edu.mm">contact@uvsyezin.edu.mm</a></span>
                                                        </div>
                                                    </address>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>                
                                </div>
                            </footer>
                            {/* <!-- End: Footer --> */}
                        </div>
                    :
                    this.state.mode == "view" ?
                        <div>
                            {/* <!-- Start: Header Section --> */}
                            <header id="header" className="navbar-wrapper">
                                <div className="container-fluid">
                                    <div className="row">
                                        <nav className="navbar navbar-default">
                                            <div className="menu-wrap">
                                                <div className="navbar-header">
                                                    <div className="navbar-brand">
                                                        <h1>
                                                            <Link to={`/`}><img src="images/uvs_e_library_logo.png" alt="UVS e-Library" /></Link>
                                                        </h1>
                                                    </div>
                                                </div>
                                                {/* <!-- Navigation --> */}
                                                <div className="navbar-collapse hidden-sm hidden-xs">
                                                    <ul className="nav navbar-nav">
                                                        <li>
                                                            <a href={base_url}>Home</a>
                                                        </li>
                                                        <li>
                                                            <a href="https://mjvas.com.mm">UVS Journals</a>
                                                        </li>
                                                        {/* <li className="dropdown">
                                                            <a data-toggle="dropdown" className="dropdown-toggle disabled" href="news-events-list-view.html">News &amp; Events</a>
                                                            <ul className="dropdown-menu">
                                                                <li><a href="news-events-list-view.html">News &amp; Events List View</a></li>
                                                                <li><a href="news-events-detail.html">News &amp; Events Detail</a></li>
                                                            </ul>
                                                        </li>
                                                        <li><a href="services.html">Services</a></li>
                                                        <li><a href="contact.html">Contact</a></li> */}
                                                    </ul>
                                                    {/* <!-- Header Topbar --> */}
                                                    <div className="header-topbar hidden-md">
                                                        <div className="topbar-links">
                                                            <a href={ api + "admin"}><i className="fa fa-lock"></i>Login</a>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Header Topbar --> */}
                                                </div>
                                            </div>
                                            <div className="mobile-menu hidden-md hidden-lg">
                                            <a href="#mobile-menu"><i className="fa fa-navicon"></i></a>
                                                <div id="mobile-menu">
                                                    <ul>
                                                        <li className="mobile-title">
                                                            <h4>Navigation</h4>
                                                            <a href="#" className="close"></a>
                                                        </li>
                                                        <li>
                                                            <a href={base_url}>Home</a>
                                                        </li>
                                                        <li>
                                                            <a href="https://mjvas.com.mm">UVS Journals</a>
                                                        </li>
                                                    
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </header>
                                
                                {/* <!-- End: Header Section --> */}

                                {/* <!-- Start: Page Banner --> */}
                                <section className="page-banner services-banner">
                                    <div className="container">
                                    <div className="breadcrumb">
                                        <ul>
                                            <li><a href="index-2.html">Home</a></li>
                                            <li>Books & Media</li>
                                        </ul>
                                    </div>
                                    </div>
                                </section>
                                {/* <!-- End: Page Banner --> */}

                                {/* <!-- Start: Products Section --> */}
                                <div id="content" className="site-content">
                                    <div id="primary" className="content-area">
                                        <main id="main" className="site-main">
                                            <div className="books-media-gird">
                                                <div className="container">
                                                    <div className="row">
                                                        {/* <!-- Start: Search Section --> */}
                                                        <section className="search-filters">
                                                            <div className="container">
                                                                <div className="filter-box">
                                                                    <h3>What are you looking for at the library?</h3>
                                                                    <form>
                                                                        <div className="col-md-4 col-sm-6">
                                                                            <div className="form-group">
                                                                                <label className="sr-only" for="keywords">Search by Keyword</label>
                                                                                <input className="form-control" placeholder="Search by Keyword" onChange={this.handleHomePageSearchKeywordChange} type="text"></input>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 col-sm-6">
                                                                    
                                                                            <div className="form-group">
                                                                                <Select
                                                                                    styles={customSearchStyles}
                                                                                    placeholder="Search By Author"
                                                                                    required
                                                                                    id="book_author_id"
                                                                                    value={this.state.author_obj}
                                                                                    options={this.state.author_data}
                                                                                    onChange={this.handleChangeBookAuthorID }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 col-sm-6">
                                                                            <div className="form-group">
                                                                                <Select
                                                                                    styles={customSearchStyles}
                                                                                    placeholder="Search by Category"
                                                                                    required
                                                                                    id="book_category_id"
                                                                                    value={this.state.category_obj}
                                                                                    options={this.state.category_data}
                                                                                    onChange={this.handleChangeBookCategoryID }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2 col-sm-6">
                                                                            <div className="form-group">
                                                                                <input className="form-control" type="submit" value="Search" onClick={this.BookListing}></input>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        {/* <!-- End: Search Section --> */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-9 col-md-push-3">
                                                            
                                                            <div className="booksmedia-detail-box">
                                                                <div className="detailed-box">
                                                                    <div className="col-xs-12 col-sm-5 col-md-3">
                                                                        <div className="post-thumbnail">
                                                                            <img src={api + "uploads/cover/" + this.state.book_info.book_cover} alt="Book Image"></img>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-7 col-md-6">
                                                                        <div className="post-center-content">
                                                                            <h2>{this.state.book_info.book_name}</h2>
                                                                            <p><strong>Author:</strong> {this.state.book_info.author_name}</p>
                                                                            <p><strong>ISBN:</strong> {this.state.book_info.book_isbn}</p>
                                                                            <p><strong>Publisher:</strong> {this.state.book_info.publisher_name}</p>
                                                                            <p><strong>Category:</strong> {this.state.book_info.category_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-3 ">
                                                                        <div className="post-right-content">
                                                                            <h4>Available now</h4>
                                                                            <p><strong>Hard Copy Book:</strong> {this.state.book_info.book_qty}</p>
                                                                            <p><strong>Find at:</strong> {this.state.book_info.book_remark}</p>
                                                                            <a className="btn btn-dark-gray" href={api +  "uploads/ebooks/" + this.state.book_info.book_ebookpath} target="_blank" download={this.state.book_info.book_name.replace(" ", "")}>
                                                                                Read / Download
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                                <p><strong>Intro:</strong> {this.state.book_info.book_intro}</p>
                                                            </div>
                                                          
                                                        </div>
                                                        <div className="col-md-3 col-md-pull-9">
                                                            <aside id="secondary" className="sidebar widget-area" data-accordion-group>
                                                                <div className="widget widget_recent_entries">
                                                                    <h4 className="widget-title">New Release Items</h4>
                                                                    <ul>
                                                                        {
                                                                            this.state.new_books.length > 0 ? 
                                                                            this.state.new_books.map((book) => {
                                                                                return <li>
                                                                                            <figure>
                                                                                                <img src={api + "uploads/cover/" + book.book_cover } width="100" height="60" alt="product" />
                                                                                            </figure>
                                                                                            <a href="#">{ book.book_name }</a>
                                                                                            <span className="price"><strong>Author:</strong> book.author_name</span>
                                                                                            <span><strong>ISBN:</strong> {book.book_isbn}</span>
                                                                                            <div className="clearfix"></div>
                                                                                        </li>
                                                                            })
                                                                        :
                                                                            <li>
                                                                                <span className="price">No new books!</span>
                                                                            </li>
                                                                        }
                                                                    </ul>
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                            </aside>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </div>
                                {/* <!-- End: Products Section --> */}
                                <div>&nbsp;</div>
                                {/* <!-- Start: Footer --> */}
                        <footer className="site-footer">
                            <div className="container">
                                <div id="footer-widgets">
                                    <div className="row">
                                        <div className="col-sm widget-container text-center">
                                            <div id="text-2" className="widget widget_text">
                                                <h3 className="footer-widget-title">About UVS e-Library</h3>
                                                <span className="underline left"></span>
                                                <div className="textwidget">
                                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking.
                                                </div>
                                                <address>
                                                    <div className="info">
                                                        <i className="fa fa-location-arrow"></i>
                                                        <span>University of Veterinary Science, Yezin, Zayar Thiri Township, Naypyidaw, Myanmar.</span>
                                                    </div>
                                                    <div className="info">
                                                        <i className="fa fa-envelope"></i>
                                                        <span><a href="mailto:contact@uvsyezin.edu.mm">contact@uvsyezin.edu.mm</a></span>
                                                    </div>
                                                </address>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>                
                            </div>
                        </footer>
                        {/* <!-- End: Footer --> */}
                    </div>
                    :

                    <div></div>
                }
                    
            </div>
        );
    }
}
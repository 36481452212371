import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class CallOfPapers extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
           
        };

    }

    render() {
        return (
            <div>
                   {/* <!-- Start: Header Section --> */} 
                   <header id="header" className="navbar-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <nav className="navbar navbar-default">
                                            <div className="menu-wrap">
                                                <div className="navbar-header">
                                                    <div className="navbar-brand">
                                                        <h1>
                                                            <Link to={`/`}><img src="images/uvs_e_library_logo.png" alt="UVS e-Library" /></Link>
                                                        </h1>
                                                    </div>
                                                </div>
                                                {/* <!-- Navigation --> */}
                                                <div className="navbar-collapse hidden-sm hidden-xs">
                                                    <ul className="nav navbar-nav">
                                                        <li>
                                                            <Link to={`/`} style={{color:'white'}}>Home</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/authorguidelines`} style={{color:'white'}}>Author Guidelines</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="mobile-menu hidden-md hidden-lg">
                                            <a href="#mobile-menu"><i className="fa fa-navicon"></i></a>
                                                <div id="mobile-menu">
                                                    <ul>
                                                        <li className="mobile-title">
                                                            <h4>Navigation</h4>
                                                            <a href="#" className="close"></a>
                                                        </li>
                                                        <li>
                                                            <Link to={`/`}>Home</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/callofpapers`}>Call of Papers</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/authorguidelines`}>Author Guidelines</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </header>
                            {/* <!-- End: Header Section -->

                        {/* <!-- Start: Page Banner --> */}
                            <section className="page-banner services-banner">
                                <div className="container">
                                    <div className="banner-header">
                                        <h2 style={{ color: "white" }}>Call of Papers</h2>
                                        <span className="underline center"></span>
                                        <p className="lead">You are invited to participate.</p>
                                    </div>
                                    <div className="breadcrumb">
                                        <ul>
                                            <li><Link to={`/`}>Home</Link></li>
                                            <li>Call of Papers</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            {/* <!-- End: Page Banner --> */}
                            <div>&nbsp;</div>
                            
                            <div id="content" className="site-content">
                                <div id="primary" className="content-area">
                                    <main id="main" className="site-main">
                                        <div className="blog-detail-main">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="blog-page">
                                                        <div className="blog-section">
                                                            <article>
                                                                <div className="blog-detail">
                                                                    
                                                                    <div className="post-detail">
                                                                        <div className="entry-content">
                                                                            <h1>Call of papers</h1>
                                                                            <p style={{ paddingTop: 20 }}>
                                                                                We are happy to inform you that <b>Myanmar Journal of Veterinary and Animal
                                                                                Sciences (MJVAS)</b> (ISSN: soon) (published by University of Veterinary Science, Yezin,
                                                                                Nay Pyi Taw) is launching its first issue soon. We would like to invite you to contribute
                                                                                your Research Paper for publication in MJVAS.
                                                                            </p>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Original papers, Review Papers, Short Communication and Clinical Reports. This
                                                                                journal is a Print & Online journal which provides an International Exposure to the
                                                                                researcher.
                                                                            </p>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Kindly submit your manuscript through journal’s email; uvsjournal@gmail.com in MS
                                                                                word format as attachment.
                                                                            </p>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                All the manuscripts under the journal will be reviewed by the reviewers and follows
                                                                                double peer review process.
                                                                            </p>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                The journal needs your support and hope will soon receive your submission.
                                                                            </p>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Best Regards
                                                                            </p>
                                                                            <p style={{ paddingTop: 10 }}>
                                                                                Editorial Board<br/>
                                                                                <b>Myanmar Journal of Veterinary and Animal Sciences (MJVAS)</b><br />
                                                                                University of Veterinary Science, Yezin, Nay Pyi Taw
                                                                            </p>
                                                                        </div>
                                                                       <div>&nbsp;</div>
                                                                       <div>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                            
                                                            <div className="comments-area" id="comments">
                                                                <div className="comment-respond" id="respond">
                                                                    <h4 className="comment-reply-title" id="reply-title">Submit your journal:</h4>
                                                                    <span className="underline left"></span>
                                                                    <form className="comment-form" id="commentform">
                                                                        <div className="row">
                                                                            <p className="comment-form-author input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Name</span>  
                                                                                    <span className="second-letter">*</span>
                                                                                </label>
                                                                                <input name="author" id="author" type="text" />
                                                                            </p>
                                                                            <p className="comment-form-email input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Email</span>  
                                                                                    <span className="second-letter">*</span>
                                                                                </label>
                                                                                <input name="email" id="email" type="email" />
                                                                            </p>
                                                                            <p className="comment-form-subject input-required">
                                                                                <label>
                                                                                    <span className="first-letter">Subject</span>
                                                                                </label>
                                                                                <input name="subject" id="subject" type="text" />
                                                                            </p>
                                                                            <p className="comment-form-comment">
                                                                                <textarea name="comment" id="comment" placeholder="Write details about your manuscript"></textarea>
                                                                            </p>
                                                                        </div>
                                                                        <div className="clearfix"></div>
                                                                        <p className="form-submit">
                                                                            <input value="Submit" className="submit" id="submit" name="submit" type="submit" /> 
                                                                        </p>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                            <div>&nbsp;</div>
                    {/* <!-- Start: Footer --> */}
                    <footer className="site-footer">
                        <div className="container">
                            <div id="footer-widgets">
                                <div className="row">
                                    <div className="col-sm widget-container text-center">
                                        <div id="text-2" className="widget widget_text">
                                            <h3 className="footer-widget-title">About UVS e-Library</h3>
                                            <span className="underline left"></span>
                                            <div className="textwidget">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking.
                                            </div>
                                            <address>
                                                <div className="info">
                                                    <i className="fa fa-location-arrow"></i>
                                                    <span>University of Veterinary Science, Yezin, Zayar Thiri Township, Naypyidaw, Myanmar.</span>
                                                </div>
                                                <div className="info">
                                                    <i className="fa fa-envelope"></i>
                                                    <span><a href="mailto:contact@uvsyezin.edu.mm">contact@uvsyezin.edu.mm</a></span>
                                                </div>
                                            </address>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>                
                        </div>
                    </footer>
                    {/* <!-- End: Footer --> */}

            </div>
        );
    }
}
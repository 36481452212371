import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Home from "./components/Home/home";
import Booksearch from "./components/BookSearch/booksearch";
import Login from "./components/LoginAndRegister/login";
import CallOfPapers from "./components/CallOfPapers/callofpapers";
import AuthorGuidelines from './components/AuthorGuidelines/authorguidelines';

function App() {
  let history = useHistory();

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/callofpapers" component={CallOfPapers} />
          <Route path="/booksearch/" component={Booksearch} />
          <Route path="/login" component={Login} />
          <Route path="/authorguidelines" component={AuthorGuidelines} />
          <Route path="/" component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
